







































import { userModule, stateModule } from '@/store'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { mixin as clickaway } from '@/plugins/vue-clickaway.ts'

import { AuthUserResource } from '@/models/users/AuthUserResource'

import UserImage from '@/components/user/UserImage.vue'
import NotificationMenu from '@/components/partials/NotificationMenu.vue'
import { NotificationsController } from '@/controllers/notifications/NotificationsController'

@Component({
  components: {
    UserImage,
    NotificationMenu,
  },
  mixins: [clickaway],
})
export default class ProfileMenu extends Vue {

  @Prop()
  private readonly notifications!: NotificationsController

  @Prop()
  private readonly notificationsPlaceholder!: string

  @Prop({ default: true })
  private readonly showActivity!: boolean

  private profileMenuOpen: boolean = false

  private get isLoggedIn(): boolean {
    return userModule.isLoggedIn
  }

  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  private get openNotifcations(): boolean | null {
    return stateModule.openNotifications
  }

  private async logout(): Promise<void> {
    if (confirm('Are you sure you want to logout?')) {
      stateModule.setLoading(true)
      try {
        await userModule.logout()
        await this.$msal.signOut()

        setTimeout(() => window.location.href = '/login', 1000)
        
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private toggleProfileMenu(): void {
    this.profileMenuOpen = !this.profileMenuOpen
  }

  private closeProfileMenu(): void {
    this.profileMenuOpen = false
  }

  private toggleNotificationsMenu(): void {
    stateModule.setNotificationsOpen(!this.openNotifcations)
    this.$nextTick(() => {
      this.$emit('notificationMenuOpen', this.openNotifcations)
    })
  }

  private toggleFeedbackForm(): void {
    stateModule.setFeedbackForm(true)
  }
}
