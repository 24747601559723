




































import { Vue, Component, Prop } from 'vue-property-decorator'

import { NotificationResource } from '@/models/notifications/NotificationResource'

import UserImage from '@/components/user/UserImage.vue'
import { userModule, stateModule } from '@/store'

@Component({
  components: {
    UserImage
  }
})
export default class NotificationListItem extends Vue {

  @Prop() private notification!: NotificationResource

  private readNotification(): void {
    this.notification.new = false
    stateModule.setNotificationsOpen(false)
  }

  private get unread(): boolean {
    return !!this.notification.new
  }

  private get isOwnNotification(): boolean {
    return this.notification.user.id === userModule.user?.id
  }

  public checkLinkExist(link: string) {
    return link !== window.location.pathname.replace(/\/$/, "")
  }

}
